<template>
    <div class="app-footer bg-sky-800">
        <layout-contained>
            <div class="py-6">
                <span class="text-gray-100">
                    &copy; {{ new Date().getFullYear() }}
                    {{ $config.public.companyNameLong }} |
                    <NuxtLink class="hover:underline" to="/">Home</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/about-us">About</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/clients">Clients</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/contact">Contact</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/terms">Terms</NuxtLink>
                    | <a class="hover:underline" :href="$config.public.files + 'Modern-Slavery-Statement.pdf'">Modern Slavery Statement</a>
                    | <a class="hover:underline" :href="$config.public.files + 'Disability-Confident-Committed-Certificate.pdf'">Disability Committed</a>
                </span>
            </div>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>
